//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { urlByAnyData } from '@/utils/util';

export default {
  components: {
    TreeView: () => import('@/components/tools/TreeView.vue'),
    PageHeader: () => import('@/components/tools/PageHeaderCustom.vue'),
    ImportModal: () => import('../../../project/components/ImportModal.vue')
  },

  data() {
    return {
      newCategoryNewOrder: 'BOTTOM',
      categoryImportVisible: false
    };
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('page', ['pageById']),
    ...mapGetters('project', ['projectById']),
    categoryNewOrder() {
      return this.currentPage.category_new_order || 'BOTTOM';
    },
    currentPage() {
      return this.pageById(this.pageId) || {};
    },
    currentProject() {
      return this.projectById(this.projectId) || {};
    },
    projectId() {
      return this.$route.params.projectId;
    },
    pageId() {
      return this.$route.params.pageId;
    },
    companyId() {
      return this.userInfo.company_id;
    }
  },
  watch: {
    categoryNewOrder: {
      handler(newVal) {
        this.newCategoryNewOrder = newVal;
      },
      immediate: true
    }
  },
  async created() {
    try {
      this.loading = true;
      this.getPageById({ projectId: this.projectId, pageId: this.pageId });
      this.getProjectById({ companyId: this.companyId, projectId: this.projectId });
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions('page', ['getPageById']),
    ...mapActions('project', ['getProjectById']),
    ...mapActions('category', ['fetchCategories']),

    async onChangeAutoCategoryOrdered(value) {
      try {
        await this.$s.api.page.editPageById(this.projectId, this.pageId, {
          category_new_order: value
        });
      } catch (error) {
        this.$notification.error({ message: this.$t('This page update failed') });
      }
    },
    async handleCategoryExport() {
      try {
        const data = await this.$s.api.page.exportCategory(this.pageId);
        const url = urlByAnyData({ data, contentType: 'application/json' });
        const a = document.createElement('a');
        a.href = url;
        a.download = this.currentPage.name + '-category' || 'file-name';
        a.click();
        a.remove();
        this.$notification.success({
          message: this.$t('Exporting category successfully.')
        });
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: this.$t('Exporting category failed.'),
          description: error?.message || ''
        });
      }
    },
    async handleCategoryImporting({ url, resolve, reject }) {
      try {
        await this.$s.api.page.importCategoryByUrl(this.pageId, url);
        this.categoryImportVisible = false;
        resolve(1);
        this.fetchCategories(this.pageId); // Reload category store
        this.$notification.success({
          message: this.$t('Importing category successfully.')
        });
      } catch (error) {
        reject(error);
        this.$notification.error({
          message: this.$t('Importing category failed.'),
          description: error?.message || ''
        });
      }
    },
    handleBackToCodeList() {
      // this.$router.push({
      //   name: 'code-list',
      //   params: {
      //     projectId: this.projectId,
      //     pageId: this.pageId
      //   }
      // });
      this.$router.go(-1);
    }
  }
};
