var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        {
          staticClass: "mb-xs",
          attrs: {
            "show-back-icon": "",
            title: _vm.currentProject.name + " / " + _vm.currentPage.name
          },
          on: { back: _vm.handleBackToCodeList }
        },
        [
          _c(
            "template",
            { slot: "extra" },
            [
              _c(
                "a-button",
                {
                  staticClass: "ml-xs",
                  attrs: { icon: "import" },
                  on: {
                    click: function($event) {
                      _vm.categoryImportVisible = true
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Import category")))]
              ),
              _c(
                "a-button",
                {
                  staticClass: "ml-xs",
                  attrs: { icon: "export" },
                  on: { click: _vm.handleCategoryExport }
                },
                [_vm._v(_vm._s(_vm.$t("Export category")))]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "a-card",
        [
          _c("tree-view", { attrs: { pageId: _vm.pageId } }, [
            _c(
              "div",
              {
                staticClass: "header-left",
                attrs: { slot: "headerLeft" },
                slot: "headerLeft"
              },
              [
                _c("p", [_vm._v(_vm._s(_vm.$t("How to add a new category")))]),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "50%" },
                    on: { change: _vm.onChangeAutoCategoryOrdered },
                    model: {
                      value: _vm.newCategoryNewOrder,
                      callback: function($$v) {
                        _vm.newCategoryNewOrder = $$v
                      },
                      expression: "newCategoryNewOrder"
                    }
                  },
                  [
                    _c("a-select-option", { attrs: { value: "TOP" } }, [
                      _vm._v(_vm._s(_vm.$t("Add to the beginning")))
                    ]),
                    _c("a-select-option", { attrs: { value: "BOTTOM" } }, [
                      _vm._v(_vm._s(_vm.$t("Add to the end")))
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.categoryImportVisible
        ? _c("ImportModal", {
            attrs: {
              visible: _vm.categoryImportVisible,
              title: "Import category",
              type: "category"
            },
            on: {
              cancel: function() {
                _vm.categoryImportVisible = false
              },
              ok: _vm.handleCategoryImporting
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }